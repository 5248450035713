import React from "react"

const Techcrunch = ({width}) => (
  <svg fill="currentColor" width={width} viewBox="0 0 1414 202">
    <path d="M396.51 0H264.34V67.3497H396.51V0Z" ></path>
    <path d="M0 0V67.3497H66.0849V202H132.17V67.3497H198.255V0H0Z" ></path>
    <path d="M198.254 202H264.339H396.558V134.65H264.339V67.3496H198.254V202Z" ></path>
    <path d="M565.011 50.917H467.896V78.8772H500.399V166.78H532.508V78.8772H565.011V50.917Z" ></path>
    <path d="M601.638 78.043C578.808 78.043 561.869 93.4456 561.869 123.417C561.869 150.053 576.304 168.595 602.473 168.595C614.973 168.689 627.251 165.291 637.921 158.784L626.53 139.752C619.689 143.74 611.916 145.856 603.995 145.883C602.096 146.019 600.19 145.76 598.397 145.121C596.604 144.483 594.963 143.479 593.578 142.174C592.193 140.869 591.095 139.291 590.353 137.54C589.61 135.789 589.24 133.903 589.265 132.001H640.327C640.327 130.481 640.327 128.666 640.327 126.213C640.376 93.789 625.45 78.043 601.638 78.043ZM589.216 113.312C590.247 104.679 594.126 100.068 600.852 100.068C609.592 100.068 612.587 104.532 614.109 113.312H589.216Z" ></path>
    <path d="M696.885 144.411C688.784 144.411 684.611 137.299 684.611 123.564C684.611 109.829 688.441 102.226 696.051 102.226C702.531 102.226 705.33 105.856 709.454 113.459L730.026 97.9093C722.416 85.646 712.94 78.2881 696.542 78.2881C668.703 78.2881 655.104 96.6829 655.104 123.319C655.104 152.75 670.373 168.84 695.903 168.84C711.32 168.84 720.747 163.052 730.861 148.139L711.958 133.424C706.508 141.272 702.531 144.411 696.885 144.411Z" ></path>
    <path d="M799.598 78.0428C791.639 77.8859 783.888 80.5833 777.75 85.646V46.1094L747.898 58.0292V166.78H777.75V117.089C777.75 106.199 781.53 103.207 787.864 103.207C794.197 103.207 797.683 106.199 797.683 117.432V166.78H827.681V113.655C827.78 90.7966 818.648 78.0428 799.598 78.0428Z" fill="white"></path>
    <path d="M899.706 140.634C885.762 140.634 880.067 126.556 880.067 108.848C880.067 91.1401 885.861 77.0619 899.313 77.0619C909.918 77.0619 914.042 83.8312 918.019 94.5738L947.183 83.1935C939.229 61.3159 927.986 48.8564 899.461 48.8564C868.333 48.8564 846.436 71.3717 846.436 108.652C846.436 142.989 864.847 168.398 899.313 168.398C926.808 168.398 939.72 153.683 947.527 138.427L920.376 124.104C914.092 135.141 909.771 140.634 899.706 140.634Z" fill="white"></path>
    <path d="M995.447 86.1372V79.9565H965.645V166.78H995.447V119.444C995.447 108.849 999.914 105.366 1007.23 105.366C1009.93 105.499 1012.58 106.218 1014.98 107.471C1017.37 108.725 1019.47 110.483 1021.12 112.626L1028.1 81.6733C1022.94 78.6707 1016.92 77.479 1011 78.2879C1005.09 79.0968 999.61 81.8598 995.447 86.1372V86.1372Z" fill="white"></path>
    <path d="M1092.22 129.549C1092.22 140.438 1088.39 143.431 1082.4 143.431C1076.41 143.431 1072.58 140.438 1072.58 129.205V79.9561H1042.58V132.982C1042.58 155.841 1051.71 168.595 1070.76 168.595C1078.68 168.756 1086.38 166.056 1092.46 160.992V166.78H1122.07V79.9561H1092.07L1092.22 129.549Z" fill="white"></path>
    <path d="M1195.71 78.0428C1187.74 77.8958 1179.98 80.5913 1173.82 85.646V79.9559H1144.01V166.78H1173.82V117.089C1173.82 106.199 1177.65 103.207 1183.93 103.207C1190.21 103.207 1193.75 106.199 1193.75 117.432V166.78H1223.75V113.655C1223.8 90.7966 1214.66 78.0428 1195.71 78.0428Z" fill="white"></path>
    <path d="M1283.11 144.411C1274.96 144.411 1270.83 137.299 1270.83 123.564C1270.83 109.829 1274.66 102.226 1282.27 102.226C1288.75 102.226 1291.55 105.856 1295.67 113.459L1316.25 97.9093C1308.64 85.646 1299.16 78.2881 1282.76 78.2881C1254.92 78.2881 1241.32 96.6829 1241.32 123.319C1241.32 152.75 1256.59 168.84 1282.12 168.84C1297.49 168.84 1306.97 163.052 1317.08 148.139L1298.18 133.424C1292.73 141.272 1289 144.411 1283.11 144.411Z" ></path>
    <path d="M1385.82 78.0428C1377.86 77.8859 1370.11 80.5833 1363.97 85.646V46.1094L1334.12 58.0292V166.78H1363.97V117.089C1363.97 106.199 1367.75 103.207 1374.08 103.207C1380.42 103.207 1383.9 106.199 1383.9 117.432V166.78H1413.9V113.655C1414 90.7966 1404.87 78.0428 1385.82 78.0428Z" ></path>
</svg>
)

export default Techcrunch
