import React from "react"
import Amazon from "./svg/amazon"
import Google from "./svg/google"
import Yc from "./svg/yc"
import { GatsbyImage } from "gatsby-plugin-image";

import { useStaticQuery, graphql } from "gatsby"

export default function Backedby() {
  const data = useStaticQuery(
    graphql`query trueQuery {
  file(relativePath: {eq: "logos/true_ventures_logo.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
  )

  return (
    <div className="md:my-32 my-24">
        <div className="max-w-9xl mx-auto font-medium text-xl mb-6">
          Trusted by companies backed by
        </div>
      <div className="flex content-center w-32 space-y-7 md:space-x-16 md:space-y-0 md:w-auto md:justify-start flex-col md:flex-row md:items-center md:max-w-2xl">
        
        <Google />
       
        <Amazon/>
        <Yc />
        <div className="w-44 md:w-120 ">
          <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
        </div>
      </div>
    </div>
  );
}
