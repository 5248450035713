import React from "react"

export default function Cta() {
  return (
    <div>
    <div className="md:my-32 mt-48 mb-24 md:max-w-lg">
      <div className="">
        <h1 className="font-medium text-2xl leading-8 mb-1">
          Guiding you every step of the way
        </h1>
      </div>
      <div className="">
        <p className="text-lg font-light mb-3">
          Planning a rebrand? Looking for a custom tailored wordmark? Don't know
          where to start or what your next step is?
        </p>
        <p className="text-lg font-light mb-6">I would love to hear about your business challenges first. No strings
        attached.</p>
          <a href="mailto:hi@lancedraws.com" className="bg-blue-600 rounded-full px-10 py-2 text-lg hover:bg-white transition-all hover:border hover:border-solid hover:text-black hover:transition-all font-medium text-white">Let's Chat</a>
      </div>
    </div>
     
      </div>
  )
}
