import React, { useRef } from "react"
import Kuala from "../components/svg/kuala"
import Mailgrid from "../components/svg/mailgrid"
import Voiceflow from "../components/svg/voiceflow"
import Kendies from "../components/svg/kendies"
import Juicybeast from "../components/svg/juicybeast"
import Gretel from "../components/svg/gretel"
import Fratelli from "../components/svg/fratelli"
import Pinjour from "../components/svg/pinjour"
import Lapine from "../components/svg/lapine"
import Grocery from "../components/svg/grocery"
import Casper from "../components/svg/casper"
import Dieming from "../components/svg/dieming"
import Kaymana from "../components/svg/kaymana"
import Panchos from "../components/svg/panchos"
import Apostrophe from "../components/svg/apostrophe"
import Beaugal from "../components/svg/beaugal"
import Quimade from "../components/svg/quimade"
import Tailwise from "../components/svg/tailwise"
import Swipp from "../components/svg/swipp"
import Muffinsroom from "../components/svg/muffinsroom"
import Mailbee from "../components/svg/mailbee"
import Citizens from "./svg/citizens"
import Melimelo from "../components/svg/melimelo"
import Thatcopy from "../components/svg/thatcopy"
import Bartenders from "../components/svg/bartenders"
import Mudddy from "../components/svg/mudddy"
import styles from "../styles/logogridtic.module.scss"
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import Bankjoy from "./svg/bankjoy"
import Cg from "./svg/cg"
import Thistinct from "./svg/thistinct"
import Athlete from "./svg/athlete"
import Chimney from "./svg/chimney"
import Signwell from "./svg/signwell"
import North from "./svg/north"
import Mighty from "./svg/mightyy"
import Thomas from "./svg/thomas"
import Storymade from "./svg/storymade"


export default function Logogridtic() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const variants = {
    show: {opacity: 1, transition: {  staggerChildren: .07 } },
    hide: { opacity: 0,  }
}
  const item = {
    show: {opacity: 1, y: 0, },
    hide: { opacity: 0, y: -30,  },
    tap: { scale: 1 },
    hover: {
      transition: { ease: "easeOut", duration: .1 },
    },
}

  const constraintsRef = useRef(null)

  return (
    <div ref={constraintsRef} className={`pl-5 mt-4 ${styles.scrolling}`}>
      <motion.div
        drag="x"
        dragConstraints={constraintsRef}
        className={`${styles.container} md:space-x-10 space-x-4`}
        ref={ref} animate={inView ? "show" : "hide"} initial="hide" variants={variants}
      >
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Voiceflow />
        </motion.div>
        <motion.div  whileHover="hover" whileTap="tap" variants={item}>
          <Cg />
        </motion.div>
        <motion.div  whileHover="hover" whileTap="tap" variants={item}>
          <Bankjoy />
        </motion.div>
        <motion.div  whileHover="hover" whileTap="tap" variants={item}>
          <Thistinct />
        </motion.div>
        <motion.div  whileHover="hover" whileTap="tap" variants={item}>
          <Athlete />
        </motion.div>
        <motion.div  whileHover="hover" whileTap="tap" variants={item}>
          <Chimney />
        </motion.div>
        <motion.div  whileHover="hover" whileTap="tap" variants={item}>
          <Mailbee />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Kuala />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Pinjour />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Mighty />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Kaymana />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Lapine />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Storymade />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <North />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Gretel />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Mailgrid />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Mudddy />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Fratelli />
        </motion.div>
        <motion.div  whileHover="hover" whileTap="tap" variants={item}>
          <Signwell />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Grocery />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Thomas />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Casper />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Juicybeast />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Panchos />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Beaugal />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Quimade />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Kendies />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Dieming />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Thatcopy />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Swipp />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Apostrophe />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Muffinsroom />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Bartenders />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Tailwise />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Citizens />
        </motion.div>
        <motion.div whileHover="hover" whileTap="tap" variants={item}>
          <Melimelo />
        </motion.div>
      </motion.div>
    </div>
  )
}
