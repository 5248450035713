import { useStaticQuery, graphql } from "gatsby"

export const useAvatars = () => {
  const data = useStaticQuery(
    graphql`query avatarsQuery {
  allFile(
    filter: {relativeDirectory: {eq: "avatars"}}
    sort: {order: ASC, fields: name}
  ) {
    nodes {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 64, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
}
`
  )
  return data
}
