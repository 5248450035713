import React, { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import Reveal from "./reveal"

function Item({ i, question, children, expanded, setExpanded }) {
  const isOpen = i === expanded
  const variant = {
    open: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 },
  }

  return (
    <Reveal cc="mt-1">
      <motion.div
        className={isOpen ? "cursor-pointer py-2 md:w-fit inline-block text-white transition-all mb-2 rounded-xl px-3 text-lg border border-solid border-realGray-400 " : "cursor-pointer py-2 md:w-fit inline-block  border border-solid border-realGray-800 hover:border-realGray-400 text-white transition-all mb-2 rounded-xl px-3 text-lg "}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        {question}
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.p
            className="overflow-hidden md:w-2/3 font-normal top-center"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variant}
            transition={{ duration: 0.8 }}
          >
            <div className="pb-4 pt-2 text-lg px-4 text-realGray-200 font-light">
              {children}
            </div>
          </motion.p>
        )}
      </AnimatePresence>
    </Reveal>
  )
}

const Firstel = () => {
  return <div>Current minimum engagement fee is $10k USD</div>
}
const Secondel = () => {
  return <div>From 3 to 12 weeks, depending on the project</div>
}
const Thirdel = () => {
  return (
    <div>
      While projects typically go from Discovery — Research — Strategy —
      Execution — Iterations to Delivery. The best process is no-process at
      first. I believe defining a process should come after discovering your
      challenges and needs. We can come up with a tailored way of working that
      fits your project best
    </div>
  )
}
const Fourthel = () => {
  return (
    <div>
      By{" "}
      <a href="mailto:hi@lancedraws.com" className="font-medium">
        sending an introductory email
      </a>
    </div>
  )
}
const Fifthel = () => {
  return (
    <div>
      Generally by{" "}
      <a href="mailto:hi@lancedraws.com" className="font-medium">
        email
      </a>
      , calls or{" "}
      <a
        href="https://slack.com/connect"
        target="_blank"
        rel="noopener noreferrer"
        className="font-medium"
      >
        Slack Connect
      </a>
    </div>
  )
}

export default function Faq() {
  const [expanded, setExpanded] = useState(true)

  return (
    <Reveal cc="flex flex-col my-12">
      <div className=" mr-4">
        <h3 className="pt-6 w-full font-medium text-xl mb-2">
          Frequently Asked Questions
        </h3>
      </div>
      <div className="inline-flex flex-col">
        <Item
          i={1}
          expanded={expanded}
          setExpanded={setExpanded}
          question="How much do you charge for a custom wordmark?"
        >
          <Firstel />
        </Item>
        <Item
          i={2}
          expanded={expanded}
          setExpanded={setExpanded}
          question="How much does it takes to design a custom wordmark?"
        >
          <Secondel />
        </Item>
        <Item
          i={3}
          expanded={expanded}
          setExpanded={setExpanded}
          question="What's your process?"
        >
          <Thirdel />
        </Item>
        <Item
          i={4}
          expanded={expanded}
          setExpanded={setExpanded}
          question="How can we start working together?"
        >
          <Fourthel />
        </Item>
        <Item
          i={5}
          expanded={expanded}
          setExpanded={setExpanded}
          question="How can we communicate throughout the project?"
        >
          <Fifthel />
        </Item>
      </div>
    </Reveal>
  )
}
