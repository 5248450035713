import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

function Case({ slug, featuredImage, cc, title, subtitle }) {
  return (
    <div key={slug} className={`${cc}`}>
      <div className="imageScale mb-4">
        <Link to={`/work/${slug}`}>
          <GatsbyImage
            image={featuredImage.childImageSharp.gatsbyImageData}
            className="rounded-md md:rounded-xl" />
        </Link>
      </div>
      <h3 className="font-medium mt-2">{title}</h3>
      <div className="text-sm font-regular mb-8 md:mb-2">{subtitle}</div>
    </div>
  );
}

function Casenotready({ slug, featuredImage, cc, title, subtitle }) {
  return (
    <div
      key={slug}
      className={`${cc} cursor-not-allowed hover:saturate-0 transition-all filter`}
    >
      <div className="mb-4">
        <GatsbyImage
          image={featuredImage.childImageSharp.gatsbyImageData}
          className="rounded-md md:rounded-lg" />
      </div>
      <div className="inline-flex items-base mb-.5">
        <h3 className="font-medium">{title}</h3>{" "}
        <div className="ml-2 rounded-md bg-realGray-800 font-medium px-1.5 py-1 text-xs inline-block">
          W.I.P
        </div>
      </div>
      <div className="text-sm font-regular mb-8 md:mb-2">{subtitle}</div>
    </div>
  );
}
export default function Cases() {
  const data = useStaticQuery(graphql`
  query CasesFeaturedImage {
  desktopImage: allMdx(
    sort: {order: ASC, fields: frontmatter___date}
    filter: {frontmatter: {type: {eq: "work"}}}
  ) {
    nodes {
      frontmatter {
        cc
        slug
        availablecc
        title
        subtitle
        featuredImageDesktop {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  mobileImage: allMdx(
    sort: {order: ASC, fields: frontmatter___date}
    filter: {frontmatter: {type: {eq: "work"}}}
  ) {
    nodes {
      frontmatter {
        cc
        slug
        availablecc
        title
        subtitle
        featuredImageMobile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`)

  return (
    <div>
      <div className=""></div>
      <div className="hidden md:block">
        <div className="flex flex-col md:grid md:grid-cols-12 md:gap-x-10 md:gap-y-8">
          {data.desktopImage.nodes.map(
            ({
              frontmatter: {
                featuredImageDesktop,
                availablecc,
                slug,
                title,
                subtitle,
                cc,
              },
            }) =>
              availablecc ? (
                <Case
                  key={slug}
                  subtitle={subtitle}
                  cc={cc}
                  featuredImage={featuredImageDesktop}
                  slug={slug}
                  title={title}
                />
              ) : (
                <Casenotready
                  key={slug}
                  subtitle={subtitle}
                  cc={cc}
                  featuredImage={featuredImageDesktop}
                  slug={slug}
                  title={title}
                />
              )
          )}
        </div>
      </div>

      <div className="md:hidden">
        {data.mobileImage.nodes.map(
          ({
            frontmatter: {
              featuredImageMobile,
              availablecc,
              slug,
              title,
              subtitle,
              cc,
            },
          }) =>
              availablecc ? (
                <Case
                  key={slug}
                  subtitle={subtitle}
                  cc={cc}
                  featuredImage={featuredImageMobile}
                  slug={slug}
                  title={title}
                />
              ) : (
                <Casenotready
                  key={slug}
                  subtitle={subtitle}
                  cc={cc}
                  featuredImage={featuredImageMobile}
                  slug={slug}
                  title={title}
                />
              )
        )}
      </div>
    </div>
  )
}
