import React from 'react'
import Reveal from '../components/reveal'
import Amazonalexa from './svg/amazonalexa'
import Bloomberg from './svg/bloomberg'
import Forbes from './svg/forbes'

import Signwelll from './svg/signwelll'
import Techcrunch from './svg/techcrunch'


export default function Clientpress() {

    return (
        <div className="my-12">
            <h5 className="md:-mb-1 font-medium text-xl">Partners Press</h5>
            <div className="max-w-3xl dimOthers">
                <a href="https://www.voiceflow.com/press/voiceflow-raises-new-round-led-by-amazons-alexa-fund" rel="noopener noreferrer nofollow" target="#blank" className="text-xl font-light flex flex-col border-b border-solid border-realGray-800 border-0 pt-5 pb-7">

                               <Reveal cc="mb-1">Voiceflow raises new round led by Amazon’s Alexa Fund</Reveal>

                            <Amazonalexa width={165}/>
                </a>
                <a href="https://techcrunch.com/2019/04/23/voiceflow-which-allows-anyone-to-make-voice-apps-without-coding-raises-3-5-million/" target="#blank" className="text-xl font-light flex flex-col border-b border-solid border-realGray-800 border-0 pt-5 pb-7">

                               <Reveal cc="mb-1">Voiceflow, which allows anyone to make voice apps without coding, raises $3.5 million</Reveal>
                            <Techcrunch width={160}/>
                </a>
                <a className="text-xl font-light flex flex-col border-b border-solid border-realGray-800 border-0 pt-5 pb-7" href="https://www.forbes.com/sites/igorbosilkovski/2021/07/29/canadian-conversational-ai-design-tool-voiceflow-raises-20-million-in-series-a/?sh=c2bbf1b46cf8" target="#blank" rel="noopener noreferrer nofollow" >

                               <Reveal cc="mb-1">Canadian Conversational AI Design Tool Voiceflow Raises $20 Million In Series A</Reveal>

                            <Forbes width={100}/>
                </a>
                <a className="text-xl font-light flex flex-col border-b border-solid border-realGray-800 border-0 pt-5 pb-7" href="https://www.bloomberg.com/press-releases/2022-02-28/bankjoy-adds-16-credit-unions-to-its-seamless-end-to-end-digital-service-ecosystem" target="#blank" rel="noopener noreferrer nofollow">

                               <Reveal cc="mb-1">Bankjoy Adds 16 Credit Unions to Its Seamless End-to-End Digital Service Ecosystem</Reveal>

                            <Bloomberg width={130}/>
                </a>
                <a className="text-xl font-light flex flex-col border-b border-solid border-realGray-800 border-0 pt-5 pb-7" href="https://www.signwell.com/docsketch-now-signwell/" target="_blank" rel="noopener noreferrer nofollow">

                               <Reveal cc="mb-1">Docsketch is now SignWell</Reveal>

                            <Signwelll width={110}/>
                </a>
            </div>
            
        </div>
    )
}
