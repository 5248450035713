import React from "react"

const Bloomberg = ({width}) => (
  <svg fill="currentColor" width={width} viewBox="0 0 849 171">
    <path d="M22.3319 22.0945H40.7698C57.6212 22.0945 65.0473 27.4194 65.0473 38.2361C65.0473 47.636 57.6212 54.0186 42.7154 54.0186H22.3319V22.0945ZM0 128.452H45.1898C68.9384 128.452 92.6871 119.968 92.6871 92.6589C92.6871 76.5173 81.3699 64.1255 65.5987 62.3326V61.9593C78.5024 58.0936 87.4018 48.6965 87.4018 34.5118C87.4018 11.6397 67.8808 2.96094 48.9141 2.96094H0V128.452ZM22.3319 73.1521H43.773C61.8489 73.1521 70.3863 77.7672 70.3863 90.8943C70.3863 107.356 54.3974 109.318 42.8822 109.318H22.3319V73.1521Z" fill="white"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M128.545 0V128.451H107.336V0H128.545Z" fill="white"></path>
    <path d="M164.05 85.9173C164.05 72.6233 173.45 60.404 189.051 60.404C204.653 60.404 214.024 72.6262 214.024 85.9173C214.024 99.2084 204.653 111.433 189.051 111.433C173.447 111.433 164.05 99.2112 164.05 85.9173ZM142.775 85.9173C142.775 112.683 163.326 130.567 189.051 130.567C214.745 130.567 235.327 112.686 235.327 85.9173C235.327 59.1484 214.748 41.2422 189.051 41.2422C163.326 41.2394 142.775 59.1512 142.775 85.9173Z" fill="white"></path>
    <path d="M266.194 85.9173C266.194 72.6233 275.594 60.404 291.196 60.404C306.797 60.404 316.169 72.6262 316.169 85.9173C316.169 99.2084 306.797 111.433 291.196 111.433C275.594 111.433 266.194 99.2112 266.194 85.9173ZM244.92 85.9173C244.92 112.683 265.473 130.567 291.196 130.567C316.89 130.567 337.471 112.686 337.471 85.9173C337.471 59.1484 316.893 41.2422 291.196 41.2422C265.47 41.2394 244.92 59.1512 244.92 85.9173Z" fill="white"></path>
    <path d="M350.427 43.3692H370.643V56.6773H370.977C374.704 48.697 382.851 41.2285 397.033 41.2285C410.16 41.2285 419.227 46.3583 423.647 57.011C429.848 46.2197 438.886 41.2285 451.623 41.2285C474.314 41.2285 482.3 57.3843 482.3 77.7706V128.455H461.026V80.245C461.026 69.6206 457.856 60.4016 445.257 60.4016C431.966 60.4016 426.986 71.3852 426.986 82.3574V128.452H405.74V77.7677C405.74 67.2819 401.484 60.4016 391.197 60.4016C377.207 60.4016 371.701 70.6499 371.701 82.0096V128.452H350.427V43.3692Z" fill="white"></path>
    <path d="M570.097 85.9171C570.097 99.2252 560.725 111.433 545.096 111.433C529.491 111.433 520.094 99.2252 520.094 85.9171C520.094 72.6232 529.491 60.3727 545.096 60.3727C560.725 60.3727 570.097 72.6232 570.097 85.9171ZM499.544 128.451H519.76V115.698H520.094C526.321 125.946 537.474 130.564 549.374 130.564C575.572 130.564 591.368 111.068 591.368 85.9143C591.368 60.746 574.517 41.2392 550.24 41.2392C534.301 41.2392 525.767 48.6652 521.344 54.1994H520.815V0H499.541V128.451H499.544Z" fill="white"></path>
    <path d="M622.265 77.4081C623.852 65.1577 631.665 57.1915 644.235 57.1915C657.529 57.1915 665.676 64.9937 665.872 77.4081H622.265ZM687.146 93.3575V87.5179C687.146 57.9013 671.018 41.2422 647.267 41.2422C621.516 41.2422 600.991 59.1512 600.991 85.9173C600.991 112.683 621.516 130.567 647.267 130.567C660.699 130.567 673.323 125.785 683.224 113.574L667.984 102.076C662.673 108.6 655.748 113.574 645.123 113.574C633.252 113.574 623.654 105.942 622.265 93.3575H687.146Z" fill="white"></path>
    <path d="M700.992 43.3692H722.266V56.8441H722.628C727.243 47.0964 736.615 41.2285 747.794 41.2285C750.463 41.2285 752.938 41.7715 755.412 42.4926V63.0457C751.88 62.1691 748.489 61.4593 745.121 61.4593C725.1 61.4593 722.264 78.3107 722.264 82.9287V128.455H700.989V43.3692H700.992Z" fill="white"></path>
    <path d="M803.001 60.4016C818.407 60.4016 828.167 70.6499 828.167 85.4002C828.167 99.5567 818.6 110.373 802.831 110.373C788.483 110.373 777.83 99.2089 777.83 85.7339C777.83 70.6499 788.092 60.4016 803.001 60.4016ZM828.167 43.3692V56.1344H827.833C821.606 45.8436 810.427 41.2285 798.553 41.2285C772.33 41.2285 756.559 60.7353 756.559 85.9177C756.559 110 773.41 129.51 798.915 129.51C809.565 129.51 820.548 125.449 826.781 117.121H827.083V123.336C827.083 140.16 820.743 151.87 800.165 151.87C788.29 151.87 779.416 147.419 770.907 139.577L758.171 156.983C770.183 167.661 784.56 171.001 800.357 171.001C833.508 171.001 848.383 151.48 848.383 120.831V43.3692H828.167Z" fill="white"></path>
</svg>
)

export default Bloomberg
