import React from "react"
import { Helmet } from "react-helmet"
import Cta from "../components/cta"
import Reveal from "../components/reveal"
import { useAvatars } from "../hooks/use-avatars"
import { GatsbyImage } from "gatsby-plugin-image";
import Backedby from "../components/backedby"
import Faq from "../components/faq"
import Services from "../components/services"
import Cases from "../components/cases"
import { motion } from "framer-motion"
import Clientpress from "../components/clientpress"
import Logogridtic from "../components/logogridtic"
import Logo from "../components/logo"
import { Link } from "gatsby"

export default function Home() {
  const avatars = useAvatars()

  // const process = [
  //   {
  //     title: "1. Listening",
  //     txt:
  //       "Understanding you, your vision, and your ambition is as important as understanding what you need. We capture together all the nuances and subtleties to find a strong narrative for your brand.",
  //   },
  //   {
  //     title: "2. Strategy",
  //     txt:
  //       "No great work is done without strategy. Defining a foundation and rationale for your brand, ensures that the ensuing design process is specific to your needs and those of your audience.",
  //   },
  //   {
  //     title: "3. Iterations, iterations, iterations",
  //     txt:
  //       "After defining a strong rationale for your branding, we iterate until it sticks. From small revisions to completely different directions. We explore, reflect, and refine different solutions until we find the right one.",
  //   },
  //   {
  //     title: "4. Support",
  //     txt:
  //       "Is there anything that can push your brand forward? Be it an e-commerce store, a company website, or a verbal identity, whatever it may be, I can connect you with talented people or agencies that will fulfill your vision.",
  //   },
  // ]

  const testimonials = [
    {
      text:
        "Lance understood our brand direction from day one and delivered a logo that captured the creativity and professionalism of our brand.",
      who: "Michael Hood",
      where: "Co-founder – Voiceflow",
      node: avatars.allFile.nodes[0],
    },
    {
      text:
        "Lance was able to fully understand what we needed. He created and meticulously refined the logo until it represented our company perfectly. The end result is a logo we’ll use for years to come.",
      who: "Alex Hughes",
      where: "Founder – Gretel",
      node: avatars.allFile.nodes[1],
    },
    {
      text:
        "Lance quickly understood what we were looking for and delivered a solid wordmark that fits our branding.",
      who: "Yowan Langlais",
      where: "Co-Founder, Creative Director – Juicy Beast",
      node: avatars.allFile.nodes[2],
    },
    {
      text:
        "Lance is a super talented letterer and identity designer. If you’re starting on a new project, I highly recommend working with him!",
      who: "Jesse Fornear",
      where: "Founder – Pinjour",
      node: avatars.allFile.nodes[3],
    },
    {
      text:
        "Lance did a great job on our logo. He immediately understood what sort of look we were going for and helped guide us to a final version that we love. I highly recommend Lance to anyone looking for a high quality wordmark for their business.",
      who: "Ruben Gamez",
      where: "Founder – SignWell",
      node: avatars.allFile.nodes[4],
    },
    {
      text:
        "Lance is a super creative and talented guy who delivers not just quality design but also promptness and professionalism which can be difficult to find in the creative fields.",
      who: "Jason Binder",
      where: "CEO – Respect",
      node: avatars.allFile.nodes[5],
    },
    {
      text:
        "Working with Lance was a pleasure. Not only are his skills some of the best I've seen but the process and communication was very easy.",
      who: "Michael Mungia",
      where: "CEO – Mudddy",
      node: avatars.allFile.nodes[6],
    },
    {
      text:
        "Working with Lance was a pleasure. His attention to detail and ability to quickly take feedback and spin out new designs was great. I would definitely recommend his work and would use him again for logo and web design work.",
      who: "Mike Fingado",
      where: "CEO – Athlete+",
      node: avatars.allFile.nodes[7],
    },
  ]
  return (
    <React.Fragment>
      <Helmet>
        <html lang="en"></html>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Lance is lettering and brand identity designer."
        ></meta>
        <title>Lance — Branding & Custom Wormdarks</title>
        <link rel="preload" href="/fonts/fonts.css" as="style"></link>
        <body className="blackTheme no-scrollbar" />
      </Helmet>
      <>
      <div>
        <div className="mt-4 md: w-min">
            <Logo width={90}/>
        </div>
        <div className="mt-6 md:mt-20 md:mb-3">
          <motion.h1 className="text-xl mb-1 font-normal leading-none md:font-light mx-auto md:text-5xl md:text-left lg:text-3xl ">
            Stand out amongst your competitors.
          </motion.h1>
          <Link to="/wordmarks" className="text-realGray-400 block hover:text-white transition-colors">View all wordmarks</Link>
        </div>
        <Logogridtic />

       

        <Cases />

        <div className="md:col-span-4"></div>
        <div className="md:col-span-3"></div>
        <div className="md:col-span-5"></div>
        <div className="md:col-span-full"></div>
        <div className="md:col-span-6"></div>

        {/* <Reveal>
          <h4 className="max-w-xl mx-auto font-bold text-4xl md:text-6xl text-center my-24 md:mt-44 md:mb-32">
            Process
          </h4>
        </Reveal> */}

        {/* <Reveal>
          {process.map(({ title, txt }) => (
            <div key={title} className="mb-24">
              <Reveal>
                <h2 className="text-center displayFont mb-2">{title}</h2>
                <p className="max-w-xl text-lg text-center mx-auto">{txt}</p>
              </Reveal>
            </div>
          ))}
        </Reveal> */}
      

        <Reveal>
          <h2 className="max-w-9xl mx-auto font-medium text-3xl md:text-3xl md:mt-44 mb-6 mt-28">
            You are in good hands
          </h2>
        </Reveal>

        <div className="grid grid-cols-1 md:max-w-2xl md:gap-x-2 space-y-10 md:space-y-24 gap-y-2">
          {testimonials.map(({ text, who, where, node }) => (
            <Reveal key={who} cc="">
              <motion.div className="mx-auto flex flex-col justify-between min-h-full">
                <p className="text-xl md:text-xl md:font-light md:mb-3 mb-3">
                  {text}
                </p>
                <div className="flex items-center mt-auto">
                  <GatsbyImage
                    image={node.childImageSharp.gatsbyImageData}
                    className="w-10 h-10 rounded-full mr-3"
                    style={{ objectFit: "cover" }}
                    alt="avatar" />
                  <div className="flex flex-col mt-0.5">
                    <span className="text-xs font-medium">{who}</span>
                    <span className="text-xs blackBlue">{where}</span>
                  </div>
                </div>
              </motion.div>
            </Reveal>
          ))}
        </div>
        <Backedby />
        <Clientpress />


        <Faq />
        <Reveal>
        <Cta />
        </Reveal>
        </div>
      </>
    </React.Fragment>
  );
}
